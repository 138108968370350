import { isArray } from 'lodash';

import { AuthenticatedUser } from '../store/auth';
import { AgentOption, Property, PropertyOverviewFields } from '../store/properties/types';

export const getPropertyFloorSize = (property: Property): number | null | undefined => {
  let floorSize = property.size;
  if (!floorSize && isArray(property.propertyOverView)) {
    const _floorSize = property.propertyOverView.find(prop => prop.name === PropertyOverviewFields.floorSize);
    if (_floorSize) floorSize = _floorSize.value as number;
  }

  return floorSize;
};

export const getPropertyErfSize = (property: Property): number | null | undefined => {
  let erfSize = property.size;
  if (!erfSize && isArray(property.propertyOverView)) {
    const _erfSize = property.propertyOverView.find(prop => prop.name === PropertyOverviewFields.erfSize);
    if (_erfSize) erfSize = _erfSize.value as number;
  }

  return erfSize;
};

export const deriveAgentOption = (agent: AuthenticatedUser): AgentOption => {
  const { _id, id, firstName, lastName, email, contactNumber, profileImageUrl } = agent;

  const displayname = `${firstName} ${lastName}`;

  return {
    label: displayname,
    value: id || _id,
    firstName,
    lastName,
    profileImageUrl,
    contact: {
      email,
      contactNumber,
    }
  };
};

/**
 * Validate latitude
 * @param {Number} latitude
 * @returns
 */
export const isLatitude = (latitude: number): boolean => {
  return isFinite(latitude) && Math.abs(latitude) <= 90;
}

/**
 * Validate longitude
 * @param {Number} longitude
 * @returns
 */
export const isLongitude = (longitude: number): boolean => {
  return isFinite(longitude) && Math.abs(longitude) <= 180;
}

/**
 * Validate coordinates
 * @param {*} latitude
 * @param {*} longitude
 * @returns
 */
export const validateCoordinates = (latitude: number, longitude: number, allowNullIsland = false): boolean => {
  const isNullIsland = latitude === 0 && longitude === 0;
  const nullIslandValidation = allowNullIsland ? true : !isNullIsland;
  return isLatitude(latitude) && isLongitude(longitude) && nullIslandValidation;
};
