import { cloneDeep, get, has, isArray, isEmpty } from "lodash";
import { AnyAction } from "redux";
import { Action } from "redux-actions";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";

import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { addGTMDataLayerWithRawData } from "../../utils/GoogleTagManager";
import {
  AUTH_LOGIN_EVENT,
  AUTH_REGISTER_EVENT,
  patchUserFbSocial,
} from "../auth";
import { enqueueSnackbar } from "../notifications";
import { SubscriptionStatuses } from "../subscriptions";
import {
  addMemberToOrganisationServiceArea,
  addOrganisationServiceArea,
  createMember,
  deleteMember,
  fetchAllHeadOfficeOrganisations,
  fetchAllOrganisations,
  fetchInvitedMembers,
  fetchMemberRoles,
  fetchOrganisationById,
  fetchOrganisationByMember,
  fetchOrganisationByName,
  fetchPendingOrganisationSubscription,
  fetchSubOrganisationById,
  inviteMember,
  openInvite,
  patchFbSocialAsset,
  patchOrganisation,
  patchOrganisationState,
  patchSmartFeedSettings,
  patchSubOrgFbSocialAsset,
  updateMemberUserDetails,
  postAuthOrgInit,
  removeMemberFromOrganisationServiceArea,
  removeNotificationRecipients,
  removeOrganisationServiceArea,
  resendMemberInvitation,
  selectOrganisation,
  selectSubOrganisation,
  setColors,
  setTestMode,
  setPaymentMethods,
  setLocaleSettings,
  setNotifications,
  setSocialAssetConfig,
  setSyndicatorSettings,
  toggleFullAddress,
  updateDefaultNotificationRecipient,
  updateMember,
  updateUserMember,
  updateMemberProfileImage,
  updateNotificationRecipients,
  updateOrganisation,
  updateShowPartners,
  updateShowReferralPartners,
  uploadCoverImage,
  uploadLogo,
  setEngagementMaximiser,
  triggerSmartFeedSyndicator,
  moveFbPageToOrg,
  removeUserFbPage,
  setCampaignSettings,
  fetchMembersWithSubOrgMembers,
  setOrganisationSettings,
  getSmartFeedEvents,
  retrySmartFeedEvent,
  updateMemberUserProfileImage,
} from "./routines";
import {
  FbSocialAsset,
  MoveFbPageToOrgPayload,
  Organisation,
  OrganisationState,
  PostAuthOrgInitPayload,
  SmartFeedSettings,
  SmartFeedTrigger,
  RemoveUserFbPagePayload,
  SmartFeedEventRequest,
} from "./types";
import queryString from "query-string";
import { replace, RouterState } from "connected-react-router";
import { getQueryParamsFromLocation } from "../../utils/url.helper";

// Get current role
function* getCurrentUser() {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";

  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

// Get current state
function* getCurrentOrg() {
  const { organisation } = yield select((state: ApplicationState) => state);
  return get(organisation, "organisation._id", undefined);
}

function* handleFetchMembersWithSubOrgMembers(action: AnyAction): any {
  try {
    const { organisationId } = action.payload;
    const { role } = yield call(getCurrentUser);

    if (!isEmpty(organisationId)) {
      yield put(fetchMembersWithSubOrgMembers.request());

      const res = yield call(
        callApi,
        "get",
        `/v3/${role}organisations/${organisationId}/members-with-suborgs`
      );

      yield put(fetchMembersWithSubOrgMembers.success(res.data));
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchMembersWithSubOrgMembers.failure(errorHandler(response)));
    } else {
      yield put(
        fetchMembersWithSubOrgMembers.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(fetchMembersWithSubOrgMembers.fulfill());
  }
}

function* handleFetchMemberRoles(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(fetchMemberRoles.request());

    const res = yield call(callApi, "get", `/v3/${role}organisations/roles`);

    yield put(fetchMemberRoles.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchMemberRoles.failure(errorHandler(response)));
    } else {
      yield put(fetchMemberRoles.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchMemberRoles.fulfill());
  }
}

function* handleSelectedOrganisation(action: AnyAction): any {
  try {
    const { selectedOrg } = action.payload;
    yield put(selectOrganisation.success(selectedOrg));

    if (has(selectedOrg, "_id")) {
      const router: RouterState = yield select(
        (state: ApplicationState) => state.router
      );
      const location = cloneDeep(router.location);
      Object.assign(location, { search: `organisation=${selectedOrg._id}` });

      yield put(replace(location));

      const loggedInUser = yield select(
        ({ auth }: ApplicationState) => auth.user
      );
      addGTMDataLayerWithRawData(
        { event: "Change Organisation", page: location.pathname },
        {
          organisation: selectedOrg,
          user: loggedInUser,
        }
      );
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(selectOrganisation.failure(errorHandler(response)));
    } else {
      yield put(selectOrganisation.failure("An unknown error occured."));
    }
  } finally {
    yield put(selectOrganisation.fulfill());
  }
}

function* handleSearchOrganisation(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(fetchOrganisationByName.request());

    const res = yield call(callApi, "post", `/v3/${role}organisations/search`, {
      data: action.payload,
    });

    yield put(fetchOrganisationByName.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchOrganisationByName.failure(errorHandler(response)));
    } else {
      yield put(fetchOrganisationByName.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchOrganisationByName.fulfill());
  }
}

function* handleUpdateOrganisation(action: AnyAction): any {
  try {
    yield put(updateOrganisation.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${action.payload.id}`,
      { data: action.payload }
    );

    yield put(updateOrganisation.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Organisation was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateOrganisation.failure(errorHandler(response)));
    } else {
      yield put(updateOrganisation.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateOrganisation.fulfill());
  }
}

function* handleAddOrganisationServiceArea(action: AnyAction): any {
  try {
    yield put(addOrganisationServiceArea.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${action.payload.id}/service-area`,
      { data: action.payload }
    );

    yield put(addOrganisationServiceArea.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Service area was added",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(addOrganisationServiceArea.failure(errorHandler(response)));
    } else {
      yield put(
        addOrganisationServiceArea.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(addOrganisationServiceArea.fulfill());
  }
}

function* handleRemoveOrganisationServiceArea(action: AnyAction): any {
  try {
    yield put(removeOrganisationServiceArea.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}organisations/${action.payload.id}/service-area`,
      { data: action.payload }
    );

    yield put(removeOrganisationServiceArea.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Service area was removed",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(removeOrganisationServiceArea.failure(errorHandler(response)));
    } else {
      yield put(
        removeOrganisationServiceArea.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(removeOrganisationServiceArea.fulfill());
  }
}

function* handleUpdateNotificationRecipients(action: AnyAction): any {
  try {
    const { organisationId, recipient } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(updateNotificationRecipients.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/notification-recipients`,
      { data: { ...recipient } }
    );
    const isUpdate = get(action, "payload.isUpdate", false);

    yield put(updateNotificationRecipients.success(res.data));
    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      {
        event: "UpdatedCC",
        page: "handleUpdateNotificationRecipients",
      },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );
    yield put(
      enqueueSnackbar({
        message: `Recipient was ${isUpdate ? "updated" : "added"}`,
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateNotificationRecipients.failure(errorHandler(response)));
    } else {
      yield put(
        updateNotificationRecipients.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(updateNotificationRecipients.fulfill());
  }
}
function* handleUpdateDefaultNotificationRecipient(action: AnyAction): any {
  try {
    const { organisationId, defaultRecipient } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(updateDefaultNotificationRecipient.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/notification-recipients/default-recipient`,
      { data: { defaultRecipient } }
    );

    yield put(updateDefaultNotificationRecipient.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      {
        event: "UpdatedNotificationSettings",
        page: "handleUpdateDefaultNotificationRecipient",
      },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );
    yield put(
      enqueueSnackbar({
        message: "Default Notification Recipient was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        updateDefaultNotificationRecipient.failure(errorHandler(response))
      );
    } else {
      yield put(
        updateDefaultNotificationRecipient.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(updateDefaultNotificationRecipient.fulfill());
  }
}

function* handleRemoveNotificationRecipients(action: AnyAction): any {
  try {
    const { organisationId, recipient } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(removeNotificationRecipients.request());
    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}organisations/${organisationId}/notification-recipients`,
      { data: { ...recipient } }
    );

    yield put(removeNotificationRecipients.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );
    addGTMDataLayerWithRawData(
      {
        event: "UpdatedCC",
        page: "handleUpdateNotificationRecipients",
      },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );

    yield put(
      enqueueSnackbar({
        message: "Recipient was removed",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(removeNotificationRecipients.failure(errorHandler(response)));
    } else {
      yield put(
        removeNotificationRecipients.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(removeNotificationRecipients.fulfill());
  }
}

function* handleAddMemberToOrganisationServiceArea(action: AnyAction): any {
  try {
    yield put(addMemberToOrganisationServiceArea.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${action.payload.id}/member/${action.payload.userId}/service-area`,
      { data: action.payload }
    );

    yield put(addMemberToOrganisationServiceArea.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Service area was added",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        addMemberToOrganisationServiceArea.failure(errorHandler(response))
      );
    } else {
      yield put(
        addMemberToOrganisationServiceArea.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(addMemberToOrganisationServiceArea.fulfill());
  }
}

function* handleRemoveMemberFromOrganisationServiceArea(
  action: AnyAction
): any {
  try {
    yield put(removeMemberFromOrganisationServiceArea.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}organisations/${action.payload.id}/member/${action.payload.userId}/service-area`,
      { data: action.payload }
    );

    yield put(removeMemberFromOrganisationServiceArea.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Service area was removed",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        removeMemberFromOrganisationServiceArea.failure(errorHandler(response))
      );
    } else {
      yield put(
        removeMemberFromOrganisationServiceArea.failure(
          "An unknown error occured."
        )
      );
    }
  } finally {
    yield put(removeMemberFromOrganisationServiceArea.fulfill());
  }
}

function* handleFetchOrganisationByMember(action: AnyAction): any {
  try {
    const userId = action.payload.userId;
    const { role } = yield call(getCurrentUser);
    yield put(fetchOrganisationByMember.request());

    const res = yield call(
      callApi,
      "get",
      `/v3/${role}organisations/member/${userId}`
    );

    yield put(fetchOrganisationByMember.success(res.data));
    const { auth } = yield select((state: ApplicationState) => state);

    const gtmEvent = get(auth, "gtmEvent");

    const _event =
      gtmEvent === `${AUTH_LOGIN_EVENT}/SUCCESS`
        ? "Login"
        : gtmEvent === `${AUTH_REGISTER_EVENT}/SUCCESS`
        ? "Registered"
        : "";

    if (!isEmpty(gtmEvent) && !isEmpty(_event)) {
      addGTMDataLayerWithRawData(
        { event: _event, page: "Organisation" },
        {
          organisation: get(res, "data[0]", get(res, "data")),
          user: get(auth, "user"),
        }
      );
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchOrganisationByMember.failure(errorHandler(response)));
    } else {
      yield put(fetchOrganisationByMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchOrganisationByMember.fulfill());
  }
}

function* handleFetchAllOrganisations(): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(fetchAllOrganisations.request());

    const res = yield call(callApi, "get", `/v3/${role}organisations`);

    yield put(fetchAllOrganisations.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchAllOrganisations.failure(errorHandler(response)));
    } else {
      yield put(fetchAllOrganisations.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchAllOrganisations.fulfill());
  }
}

function* handleUpdateMemberUserProfileImage(action: AnyAction): any {
  try {
    yield put(updateMemberUserProfileImage.success(action.payload));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateMemberUserProfileImage.failure(errorHandler(response)));
    } else {
      yield put(
        updateMemberUserProfileImage.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(updateMemberUserProfileImage.fulfill());
  }
}

function* handleResetSubOrganisation(action: AnyAction): any {
  try {
    const { subOrganisation } = action.payload;
    yield put(selectSubOrganisation.success(subOrganisation));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(selectSubOrganisation.failure(errorHandler(response)));
    } else {
      yield put(selectSubOrganisation.failure("An unknown error occured."));
    }
  } finally {
    yield put(selectSubOrganisation.fulfill());
  }
}

function* handleFetchSubOrganisationById(action: AnyAction): any {
  try {
    const { subOrganisation } = action.payload;
    const organisationId = yield call(getCurrentOrg);

    const { memberOrganisations } = yield select(
      (state: ApplicationState) => state.organisation
    );
    const mainOrgId = get(memberOrganisations, "[0]._id") || organisationId;

    yield put(fetchSubOrganisationById.request());
    const res = yield call(
      callApi,
      "get",
      `/v3/organisations/${mainOrgId}/sub-organisation/${subOrganisation}`
    );

    yield put(fetchSubOrganisationById.success(res.data));

    const router: RouterState = yield select(
      (state: ApplicationState) => state.router
    );
    const loggedInUser = yield select(
      ({ auth }: ApplicationState) => auth.user
    );
    addGTMDataLayerWithRawData(
      { event: "Select Sub Organisation", page: router.location.pathname },
      {
        organisation: res.data,
        user: loggedInUser,
      }
    );

    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchSubOrganisationById.failure(errorHandler(response)));
    } else {
      yield put(fetchSubOrganisationById.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchSubOrganisationById.fulfill());
  }
}

function* handleFetchAllHeadOfficeOrganisations(): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(fetchAllHeadOfficeOrganisations.request());

    const res = yield call(
      callApi,
      "get",
      `/v3/${role}organisations/head-offices`
    );

    yield put(fetchAllHeadOfficeOrganisations.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        fetchAllHeadOfficeOrganisations.failure(errorHandler(response))
      );
    } else {
      yield put(
        fetchAllHeadOfficeOrganisations.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(fetchAllHeadOfficeOrganisations.fulfill());
  }
}

// Add new member
function* handleAddMember(action: AnyAction): any {
  try {
    const orgId = action.payload.organisationId;
    const { role } = yield call(getCurrentUser);
    yield put(createMember.request());
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${orgId}/members`,
      { data: action.payload }
    );
    if (!has(res, "data.newUser")) {
      yield put(createMember.success(res.data));
      yield put(
        enqueueSnackbar({
          message: "Organisation was updated",
          options: {
            variant: "success",
          },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: "User does not exist, please send an invitation",
          options: {
            variant: "info",
          },
        })
      );
      yield put(
        openInvite.success({ open: true, email: action.payload.email })
      );
    }

    return res.data;
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(createMember.failure(errorHandler(response)));
    } else {
      yield put(createMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(createMember.fulfill());
  }
}

// Assign Role
function* handleUpdateMember(action: AnyAction): any {
  try {
    const orgId = action.payload.organisationId;
    const { role } = yield call(getCurrentUser);
    yield put(updateMember.request());
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${orgId}/member`,
      { data: action.payload }
    );
    yield put(updateMember.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Member was updated!",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(updateMember.failure(errorHandler(response)));
    } else {
      yield put(updateMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateMember.fulfill());
  }
}

function* handleUpdateUserMember(action: AnyAction): any {
  try {
    const userId = action.payload.id;
    const { role } = yield call(getCurrentUser);

    yield put(updateUserMember.request());
    const res = yield call(callApi, "put", `${role}users/${userId}`, {
      data: action.payload,
    });
    yield put(updateUserMember.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "User was updated!",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(updateUserMember.failure(errorHandler(response)));
    } else {
      yield put(updateUserMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateUserMember.fulfill());
  }
}

// Add non-exisiting member
function* handleInviteMember(action: AnyAction): any {
  try {
    const orgId = action.payload.organisationId;
    const { role } = yield call(getCurrentUser);
    const inviteUser = action.payload.inviteUser;

    yield put(inviteMember.request());
    const res = yield call(
      callApi,
      "post",
      `/v3/${role}organisations/${orgId}/members`,
      { data: action.payload }
    );

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      {
        event: "InvitedMember",
        page: "handleUpdateDefaultNotificationRecipient",
      },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );
    yield put(inviteMember.success(res.data));
    yield put(
      enqueueSnackbar({
        message: `${inviteUser ? 'Member was invited' : 'Member was added'}`,
        options: {
          variant: "success",
        },
      })
    );

    return res.data;
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(inviteMember.failure(errorHandler(response)));
    } else {
      yield put(inviteMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(inviteMember.fulfill());
  }
}

// get organisation by Id
function* handleFetchOrganisationById(action: AnyAction): any {
  try {
    const { organisation } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(fetchOrganisationById.request());
    const res = yield call(
      callApi,
      "get",
      `/v3/${role}organisations/${organisation}`
    );
    yield put(fetchOrganisationById.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchOrganisationById.failure(errorHandler(response)));
    } else {
      yield put(fetchOrganisationById.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchOrganisationById.fulfill());
  }
}

//open form
function* handleUpdateMemberUserDetails(action: AnyAction): any {
  try {
    yield put(updateMemberUserDetails.success(action.payload));
  } catch (error) {
    const response = (error as any).response;
    yield put(updateMemberUserDetails.failure(errorHandler(response)));
  } finally {
    yield put(updateMemberUserDetails.fulfill());
  }
}

//open form
function* handleOpenInvite(action: AnyAction): any {
  try {
    yield put(openInvite.success(action.payload));
  } catch (error) {
    const response = (error as any).response;
    yield put(openInvite.failure(errorHandler(response)));
  } finally {
    yield put(openInvite.fulfill());
  }
}

// Update member
function* handleDeleteMember(action: AnyAction): any {
  try {
    const orgId = action.payload.organisationId;
    const { role } = yield call(getCurrentUser);
    yield put(deleteMember.request());
    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}organisations/${orgId}/members`,
      { data: action.payload }
    );
    yield put(deleteMember.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Member was deleted",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(deleteMember.failure(errorHandler(response)));
    } else {
      yield put(deleteMember.failure("An unknown error occured."));
    }
  } finally {
    yield put(deleteMember.fulfill());
  }
}

// set notification status
function* handleSetNotifications(action: AnyAction): any {
  try {
    const { organisationId, notifications } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setNotifications.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/notifications`,
      { data: { notifications } }
    );

    yield put(setNotifications.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      { event: "UpdatedNotificationSettings", page: "organisation" },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );

    yield put(
      enqueueSnackbar({
        message: "Notification was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setNotifications.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setNotifications.failure("An unknown error occured."));
    }
  } finally {
    yield put(setNotifications.fulfill());
  }
}

/**
 * set Engagement Maximisers
 */
function* handleSetEngagementMaximiser(action: AnyAction): any {
  try {
    const { organisationId, engagementSettings } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setEngagementMaximiser.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/engagementMaximiserSettings`,
      { data: { ...engagementSettings } }
    );

    yield put(setEngagementMaximiser.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Engagement maximisers were updated",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setEngagementMaximiser.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setEngagementMaximiser.failure("An unknown error occured."));
    }
  } finally {
    yield put(setEngagementMaximiser.fulfill());
  }
}

// set colors
function* handleSetColors(action: AnyAction): any {
  try {
    const { organisationId, colors } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setColors.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/colors`,
      { data: { colors } }
    );

    yield put(setColors.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      { event: "UpdatedColorSettings", page: "organisation" },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );

    yield put(
      enqueueSnackbar({
        message: "Colors were updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setColors.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setColors.failure("An unknown error occured."));
    }
  } finally {
    yield put(setColors.fulfill());
  }
}
// set testMode
function* handleSetTestMode(action: AnyAction): any {
  try {
    const { organisationId, testMode } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setTestMode.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/test-mode`,
      { data: { testMode } }
    );

    yield put(setTestMode.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      { event: "UpdatedTestModeSettings", page: "organisation" },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );

    yield put(
      enqueueSnackbar({
        message: `Test mode has been ${
          testMode.enabled ? "enabled" : "disabled"
        }`,
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setTestMode.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setTestMode.failure("An unknown error occured."));
    }
  } finally {
    yield put(setTestMode.fulfill());
  }
}
// set payment methods
function* handleSetPaymentMethods(action: AnyAction): any {
  try {
    const { organisationId, paymentMethods } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setPaymentMethods.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/paymentMethods`,
      { data: { paymentMethods } }
    );

    yield put(setPaymentMethods.success(res.data));

    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    addGTMDataLayerWithRawData(
      { event: "UpdatedPaymentMethodSettings", page: "organisation" },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );

    yield put(
      enqueueSnackbar({
        message: "Payment methods were updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setPaymentMethods.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setPaymentMethods.failure("An unknown error occured."));
    }
  } finally {
    yield put(setPaymentMethods.fulfill());
  }
}

// set notification status
function* handleSetOrganisationSettings(action: AnyAction): any {
  try {
    const { organisationId, settings, settingsGroupName } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setOrganisationSettings.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/settings`,
      { data: { settings, settingsGroupName } }
    );

    yield put(setOrganisationSettings.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Organisation settings were updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setOrganisationSettings.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setOrganisationSettings.failure("An unknown error occured."));
    }
  } finally {
    yield put(setOrganisationSettings.fulfill());
  }
}

// set notification status
function* handleSetLocaleSettings(action: AnyAction): any {
  try {
    const { organisationId, locale } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setLocaleSettings.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/locales`,
      { data: { locale } }
    );

    yield put(setLocaleSettings.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Localisation settings was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setLocaleSettings.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setLocaleSettings.failure("An unknown error occured."));
    }
  } finally {
    yield put(setLocaleSettings.fulfill());
  }
}

//set syndicator settings
function* handleSetSyndicatorSettings(action: AnyAction): any {
  try {
    const { organisationId, syndicatorSettings } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setSyndicatorSettings.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/syndicatorsettings`,
      { data: { syndicatorSettings } }
    );

    yield put(setSyndicatorSettings.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Syndicator settings was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setSyndicatorSettings.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setSyndicatorSettings.failure("An unknown error occured."));
    }
  } finally {
    yield put(setSyndicatorSettings.fulfill());
  }
}

// upload logo
function* handleUploadLogo(action: AnyAction): any {
  try {
    const { file, organisationId } = action.payload;
    const { role } = yield call(getCurrentUser);
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = yield call(
      callApi,
      "post",
      `/v3/${role}organisations/${organisationId}/logo`,
      config
    );

    yield put(uploadLogo.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(uploadLogo.failure(response.data.message));
    } else if (response) {
      yield put(uploadLogo.failure(errorHandler(response)));
    } else {
      yield put(uploadLogo.failure("An unknown error occured."));
    }
  } finally {
    yield put(uploadLogo.fulfill());
  }
}

// upload coverImage
function* handleUploadCoverImage(action: AnyAction): any {
  try {
    const { auth, organisation } = yield select(
      (state: ApplicationState) => state
    );

    const { file, organisationId } = action.payload;
    const { role } = yield call(getCurrentUser);
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = yield call(
      callApi,
      "post",
      `/v3/${role}organisations/${organisationId}/coverImage`,
      config
    );

    yield put(uploadCoverImage.success(res.data));

    addGTMDataLayerWithRawData(
      { event: "UpdatedCoverImage", page: "organisation" },
      {
        organisation: get(organisation, "organisation"),
        user: get(auth, "user"),
      }
    );
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(uploadCoverImage.failure(response.data.message));
    } else if (response) {
      yield put(uploadCoverImage.failure(errorHandler(response)));
    } else {
      yield put(uploadCoverImage.failure("An unknown error occured."));
    }
  } finally {
    yield put(uploadCoverImage.fulfill());
  }
}

//#region Social Asset Config
function* handleSetSocialAssetConfig(action: AnyAction): any {
  try {
    const { organisationId, configData, configType } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(setSocialAssetConfig.request());
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/socialassetconfig`,
      { data: { configData, configType } }
    );

    const isMember = !!get(configData, "isMember");
    const userSocial = isMember ? get(res, "data.social") : {};

    if (isMember) yield put(patchUserFbSocial({ ...userSocial }));
    else yield put(setSocialAssetConfig.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Social Asset Configuration was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setSocialAssetConfig.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(setSocialAssetConfig.failure("An unknown error occured."));
    }
  } finally {
    yield put(setSocialAssetConfig.fulfill());
  }
}
//#endregion
// upload member picture
function* handleUpdateMemberProfileImage(action: AnyAction): any {
  try {
    const { userId, fileData } = action.payload;
    const formData = new FormData();
    formData.append("file", fileData);

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = yield call(
      callApi,
      "post",
      `/v3/organisations/member/${userId}/image`,
      config
    );

    yield put(updateMemberProfileImage.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(updateMemberProfileImage.failure(response.data.message));
    } else if (response) {
      yield put(updateMemberProfileImage.failure(errorHandler(response)));
    } else {
      yield put(updateMemberProfileImage.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateMemberProfileImage.fulfill());
  }
}

// toggle full address
function* handleToggleFullAddress(action: AnyAction): any {
  try {
    const { organisationId, address } = action.payload;
    const { role } = yield call(getCurrentUser);

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/address`,
      { data: { address } }
    );

    yield put(toggleFullAddress.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Address settings updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(toggleFullAddress.failure(errorHandler(response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(toggleFullAddress.failure("An unknown error occured."));
    }
  } finally {
    yield put(toggleFullAddress.fulfill());
  }
}

function* handleShowPartners(action: AnyAction): any {
  const { showPartners, organisation, user } = action.payload;
  const { role } = yield call(getCurrentUser);
  try {
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisation._id}/partners`,
      {
        data: {
          organisation,
          partners: showPartners,
          user,
        },
      }
    );
    yield put(updateShowPartners.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Show partners settings updated",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(updateShowPartners.failure(errorHandler(response)));
    } else {
      yield put(updateShowPartners.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateShowPartners.fulfill());
  }
}

function* handleCampaignSettings(action: AnyAction): any {
  const { campaignSettings, organisationId } = action.payload;
  const { role } = yield call(getCurrentUser);
  try {
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/campaignSettings`,
      {
        data: {
          campaignSettings,
        },
      }
    );
    yield put(setCampaignSettings.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Campaign settings updated",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(setCampaignSettings.failure(errorHandler(response)));
    } else {
      yield put(setCampaignSettings.failure("An unknown error occured."));
    }
  } finally {
    yield put(setCampaignSettings.fulfill());
  }
}

function* handleShowReferralPartners(action: AnyAction): any {
  const { showReferralPartners, organisation, user } = action.payload;
  const { role } = yield call(getCurrentUser);
  try {
    const res = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisation._id}/referrals`,
      {
        data: {
          organisation,
          partners: showReferralPartners,
          user,
        },
      }
    );
    yield put(updateShowReferralPartners.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Show partner referrals setting updated",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(updateShowReferralPartners.failure(errorHandler(response)));
    } else {
      yield put(
        updateShowReferralPartners.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(updateShowReferralPartners.fulfill());
  }
}

function* handleResendMemberInvite(action: AnyAction): any {
  try {
    const { organisationId } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(resendMemberInvitation.request());

    const res = yield call(
      callApi,
      "post",
      `/v3/${role}organisations/${organisationId}/member/invite`,
      { data: action.payload }
    );

    yield put(resendMemberInvitation.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Invitation was successfully sent!",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(resendMemberInvitation.failure(response.data.message));
    } else if (response) {
      yield put(resendMemberInvitation.failure(errorHandler(response)));
    } else {
      yield put(resendMemberInvitation.failure("An unknown error occured."));
    }
  } finally {
    yield put(resendMemberInvitation.fulfill());
  }
}

function* handleFetchInvitedMembers(action: AnyAction): any {
  try {
    const { organisationId } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(fetchInvitedMembers.request());

    const res = yield call(
      callApi,
      "get",
      `/v3/${role}organisations/${organisationId}/members`
    );

    yield put(fetchInvitedMembers.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(fetchInvitedMembers.failure(response.data.message));
    } else if (response) {
      yield put(fetchInvitedMembers.failure(errorHandler(response)));
    } else {
      yield put(fetchInvitedMembers.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchInvitedMembers.fulfill());
  }
}

function* handlePatchOrganisation(action: Action<Partial<Organisation>>): any {
  try {
    yield put(patchOrganisation.request());
    yield put(patchOrganisation.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchOrganisation.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchOrganisation.fulfill());
  }
}

function* handleFetchPendingOrganisationSubscription(action: AnyAction): any {
  try {
    yield put(fetchPendingOrganisationSubscription.request());
    const { role } = yield call(getCurrentUser);

    const organisationId = get(action, "payload.organisationId");
    const status = SubscriptionStatuses.Pending;

    const response = yield call(
      callApi,
      "get",
      `/${role}subscriptions?${queryString.stringify({
        organisationId,
        status,
      })}`
    );

    yield put(fetchPendingOrganisationSubscription.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchPendingOrganisationSubscription.fulfill());
  }
}

function* handlePatchOrganisationState(
  action: Action<Partial<OrganisationState>>
): any {
  try {
    yield put(patchOrganisationState.request());
    yield put(patchOrganisationState.success(action.payload));

    if (action.payload.organisation) {
      const router: RouterState = yield select(
        (state: ApplicationState) => state.router
      );
      const loggedInUser = yield select(
        ({ auth }: ApplicationState) => auth.user
      );
      addGTMDataLayerWithRawData(
        { event: "Select Organisation", page: router.location.pathname },
        {
          organisation: action.payload.organisation,
          user: loggedInUser,
        }
      );
    }
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchOrganisationState.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchOrganisationState.fulfill());
  }
}

function* handlePatchFbSocialAsset(
  action: Action<Partial<FbSocialAsset>>
): any {
  try {
    yield put(patchFbSocialAsset.request());
    yield put(patchFbSocialAsset.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchFbSocialAsset.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchFbSocialAsset.fulfill());
  }
}

function* handlePatchSubOrgFbSocialAsset(
  action: Action<Partial<FbSocialAsset>>
): any {
  try {
    yield put(patchSubOrgFbSocialAsset.request());
    yield put(patchSubOrgFbSocialAsset.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchSubOrgFbSocialAsset.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchSubOrgFbSocialAsset.fulfill());
  }
}

function* handlePostAuthOrgInit(action: Action<PostAuthOrgInitPayload>): any {
  try {
    yield put(postAuthOrgInit.request());

    const organisationId = action.payload && action.payload.organisationId;
    const user = action.payload && action.payload.user;
    const userId = user && user.id;

    let fetchByIdFailed = false;
    let organisation: Organisation | null | undefined = null;
    let subOrganisation: Organisation | undefined;
    const organisations: Organisation[] = [];

    if (userId) {
      // get organisations where user is a member
      yield put(fetchOrganisationByMember({ userId }));
      const fetchByMemberResultAction = yield take([
        fetchOrganisationByMember.SUCCESS,
        fetchOrganisationByMember.FAILURE,
      ]);

      if (
        fetchByMemberResultAction.type === fetchOrganisationByMember.SUCCESS &&
        isArray(fetchByMemberResultAction.payload)
      ) {
        organisations.push(...fetchByMemberResultAction.payload);
      }
    }

    if (organisationId) {
      organisation = organisations.find((org) => org._id === organisationId);

      if (!organisation) {
        // first check to see if organisationId belongs to one of the sub organisations
        let _subOrganisation: Pick<Organisation, "_id"> | null | undefined =
          null;
        for (const org of organisations) {
          if (!isEmpty(org.subOrganisations)) {
            _subOrganisation = org.subOrganisations!.find(
              (subOrg) => subOrg._id === organisationId
            );
            if (!isEmpty(_subOrganisation)) {
              yield put(patchOrganisationState({ organisation: org }));
              organisation = org;
              break;
            }
          }
        }
        if (!isEmpty(_subOrganisation)) {
          // fetch sub organisation
          yield put(
            fetchSubOrganisationById({ subOrganisation: _subOrganisation!._id })
          );
          const fetchSubOrgByIdResultAction: Action<Organisation | string> =
            yield take([
              fetchSubOrganisationById.SUCCESS,
              fetchSubOrganisationById.FAILURE,
            ]);

          if (
            fetchSubOrgByIdResultAction.type ===
            fetchSubOrganisationById.FAILURE
          )
            fetchByIdFailed = true;
          else if (
            fetchSubOrgByIdResultAction.type ===
            fetchSubOrganisationById.SUCCESS
          )
            subOrganisation =
              fetchSubOrgByIdResultAction.payload as Organisation;
        } else {
          // attempt to fetch organisation by id
          yield put(fetchOrganisationById({ organisation: organisationId }));
          const fetchByIdResultAction: Action<Organisation | string> =
            yield take([
              fetchOrganisationById.SUCCESS,
              fetchOrganisationById.FAILURE,
            ]);

          if (fetchByIdResultAction.type === fetchOrganisationById.FAILURE)
            fetchByIdFailed = true;
          else if (fetchByIdResultAction.type === fetchOrganisationById.SUCCESS)
            organisation = fetchByIdResultAction.payload as Organisation;
        }
      }
    }

    // either fetching organisation by id failed or we couldn't get the organisation query param
    if (!organisation && organisations.length) organisation = organisations[0];

    // none super user trying to access another organisation via query param, change param to the correct one
    if (
      fetchByIdFailed &&
      organisation &&
      !user.isSuperUser &&
      organisationId !== organisation!._id
    ) {
      const router: RouterState = yield select(
        (state: ApplicationState) => state.router
      );
      const queryParams = getQueryParamsFromLocation(router.location);
      queryParams.set("organisation", organisation._id);

      const location = cloneDeep(router.location);
      Object.assign(location, { search: queryParams.toString() });
      yield put(replace(location));
    }

    yield put(postAuthOrgInit.success({ organisation, subOrganisation }));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(postAuthOrgInit.failure(errorHandler(err)));
    }
  } finally {
    yield put(postAuthOrgInit.fulfill());
  }
}

function* handleMoveFbPageToOrg(action: Action<MoveFbPageToOrgPayload>): any {
  try {
    yield put(moveFbPageToOrg.request());

    const res = yield call(
      callApi,
      "post",
      `/facebook/move-user-page-to-organisation`,
      {
        data: action.payload,
      }
    );
    yield put(moveFbPageToOrg.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Facebook page transferred successfully",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      yield put(moveFbPageToOrg.failure(errorHandler(err)));
      yield put(
        enqueueSnackbar({
          message: "Facebook page transfer failed",
          options: {
            variant: "error",
          },
        })
      );
    }
  } finally {
    yield put(moveFbPageToOrg.fulfill());
  }
}

function* handlePatchSmartFeedSettings(
  action: Action<Partial<SmartFeedSettings & { isInherited: boolean }>>
): any {
  const { organisationId, enabled, mappings, isInherited } = action.payload;
  const { role } = yield call(getCurrentUser);
  yield put(patchSmartFeedSettings.request());
  try {
    const response = yield call(
      callApi,
      "put",
      `/v3/${role}organisations/${organisationId}/smart-feed-settings`,
      {
        data: {
          enabled,
          mappings,
          isInherited,
        },
      }
    );
    yield put(patchSmartFeedSettings.success(response.data));
    yield put(
      enqueueSnackbar({
        message: "Smart feed settings was updated",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchSmartFeedSettings.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchSmartFeedSettings.fulfill());
  }
}

function* handleSmartFeedEvents(
  action: Action<Partial<SmartFeedEventRequest>>
): any {
  const { organisationId, status, user, limit, skip } = action.payload;

  const { role } = yield call(getCurrentUser);
  yield put(getSmartFeedEvents.request());
  try {
    const _limit = limit || 20;
    const _skip = skip || 0;
    let queryParams = `?limit=${_limit}&skip=${_skip}`;
    if (status) {
      queryParams += `&status=${status}`;
    }
    if (user) {
      queryParams += `&user=${user}`;
    }
    const response = yield call(
      callApi,
      "get",
      `/v3/${role}organisations/${organisationId}/smart-feed-events${queryParams}`
    );
    yield put(getSmartFeedEvents.success(response.data));
  } catch (err) {
    if (err) {
      yield put(getSmartFeedEvents.failure(errorHandler(err)));
    }
  } finally {
    yield put(getSmartFeedEvents.fulfill());
  }
}

function* handleRetrySmartFeedEvent(
  action: Action<Partial<{ event: any }>>
): any {
  const { event: data } = action.payload;
  const { role } = yield call(getCurrentUser);
  yield put(retrySmartFeedEvent.request());
  try {
    const response = yield call(
      callApi,
      "post",
      `/v3/${role}organisations/retry-smart-feed-event`,
      {
        data,
      }
    );
    yield put(retrySmartFeedEvent.success(response.data));
    yield put(
      enqueueSnackbar({
        message: "Smart feed event retried successfully",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      yield put(
        enqueueSnackbar({
          message: "Failed to retry smart feed event",
          options: {
            variant: "error",
          },
        })
      );
      yield put(retrySmartFeedEvent.failure(errorHandler(err)));
    }
  } finally {
    yield put(retrySmartFeedEvent.fulfill());
  }
}

function* handleRemoveUserFbPage(action: Action<RemoveUserFbPagePayload>): any {
  try {
    yield put(removeUserFbPage.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "post",
      `/${role}facebook/remove-fb-page-from-user`,
      {
        data: action.payload,
      }
    );

    yield put(removeUserFbPage.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Facebook page removed successfully",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      yield put(removeUserFbPage.failure(errorHandler(err)));
      yield put(
        enqueueSnackbar({
          message: "Facebook page removal failed",
          options: {
            variant: "error",
          },
        })
      );
    }
  } finally {
    yield put(removeUserFbPage.fulfill());
  }
}

function* handleSmartFeedTriggerSyndicator(
  action: Action<Partial<SmartFeedTrigger>>
): any {
  const { organisationId } = action.payload;
  const { role } = yield call(getCurrentUser);
  yield put(triggerSmartFeedSyndicator.request());
  try {
    yield call(
      callApi,
      "get",
      `/v3/${role}organisations/${organisationId}/smart-feed-trigger`
    );
    yield put(triggerSmartFeedSyndicator.success());
    yield put(
      enqueueSnackbar({
        message: "Smart feed trigger completed",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(triggerSmartFeedSyndicator.failure(errorHandler(err)));
    }
  } finally {
    yield put(triggerSmartFeedSyndicator.fulfill());
  }
}
// Error handlers
function* handleAccountError(action: AnyAction): any {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* organisationErrorWatcher() {
  yield takeEvery(
    [
      fetchOrganisationByName.FAILURE,
      fetchMembersWithSubOrgMembers.FAILURE,
      fetchOrganisationByMember.FAILURE,
      updateOrganisation.FAILURE,
      createMember.FAILURE,
      inviteMember.FAILURE,
      openInvite.FAILURE,
      deleteMember.FAILURE,
      setNotifications.FAILURE,
      setColors.FAILURE,
      setTestMode.FAILURE,
      setPaymentMethods.FAILURE,
      setLocaleSettings.FAILURE,
      setSyndicatorSettings.FAILURE,
      fetchAllOrganisations.FAILURE,
      selectOrganisation.FAILURE,
      selectSubOrganisation.FAILURE,
      fetchOrganisationById.FAILURE,
      fetchMemberRoles.FAILURE,
      updateMember.FAILURE,
      removeOrganisationServiceArea.FAILURE,
      addOrganisationServiceArea.FAILURE,
      removeMemberFromOrganisationServiceArea.FAILURE,
      addMemberToOrganisationServiceArea.FAILURE,
      updateNotificationRecipients.FAILURE,
      updateDefaultNotificationRecipient.FAILURE,
      removeNotificationRecipients.FAILURE,
      setSocialAssetConfig.FAILURE,
      updateMemberProfileImage.FAILURE,
      fetchSubOrganisationById.FAILURE,
      toggleFullAddress.FAILURE,
      resendMemberInvitation.FAILURE,
      fetchInvitedMembers.FAILURE,
      triggerSmartFeedSyndicator.FAILURE,
      setCampaignSettings.FAILURE,
      setOrganisationSettings.FAILURE,
    ],
    handleAccountError
  );
}

function* handleResetSubOrganisationWatcher() {
  yield takeEvery(selectSubOrganisation.TRIGGER, handleResetSubOrganisation);
}

function* handleFetchSubOrganisationByIdWatcher() {
  yield takeEvery(
    fetchSubOrganisationById.TRIGGER,
    handleFetchSubOrganisationById
  );
}

function* handleFetchMembersWithSubOrgMembersWatcher() {
  yield takeEvery(
    fetchMembersWithSubOrgMembers.TRIGGER,
    handleFetchMembersWithSubOrgMembers
  );
}

function* fetchOrganisationByMemberWatcher() {
  yield takeEvery(
    fetchOrganisationByMember.TRIGGER,
    handleFetchOrganisationByMember
  );
}

function* handleInviteMemberWatcher() {
  yield takeEvery(inviteMember.TRIGGER, handleInviteMember);
}

function* updateOrganisationWatcher() {
  yield takeEvery(updateOrganisation.TRIGGER, handleUpdateOrganisation);
}

function* handleAddMemberWatcher() {
  yield takeEvery(createMember.TRIGGER, handleAddMember);
}

function* handleDeleteMemberWatcher() {
  yield takeEvery(deleteMember.TRIGGER, handleDeleteMember);
}

function* handleUploadLogoWatcher() {
  yield takeEvery(uploadLogo.TRIGGER, handleUploadLogo);
}

function* handleUploadCoverImageWatcher() {
  yield takeEvery(uploadCoverImage.TRIGGER, handleUploadCoverImage);
}

function* handleSetNotificationsWatcher() {
  yield takeEvery(setNotifications.TRIGGER, handleSetNotifications);
}

function* handleSetColorsWatcher() {
  yield takeEvery(setColors.TRIGGER, handleSetColors);
}

function* handleSetTestModeWatcher() {
  yield takeEvery(setTestMode.TRIGGER, handleSetTestMode);
}

function* handleSetPaymentMethodsWatcher() {
  yield takeEvery(setPaymentMethods.TRIGGER, handleSetPaymentMethods);
}

function* handleSetEngagementMaximiserWatcher() {
  yield takeEvery(setEngagementMaximiser.TRIGGER, handleSetEngagementMaximiser);
}

function* handleSetOrganisationSettingsWatcher() {
  yield takeEvery(
    setOrganisationSettings.TRIGGER,
    handleSetOrganisationSettings
  );
}

function* handleSetLocaleSettingsWatcher() {
  yield takeEvery(setLocaleSettings.TRIGGER, handleSetLocaleSettings);
}

function* handleSetSyndicatorSettingsWatcher() {
  yield takeEvery(setSyndicatorSettings.TRIGGER, handleSetSyndicatorSettings);
}

function* handleSetSocialAssetConfigWatcher() {
  yield takeEvery(setSocialAssetConfig.TRIGGER, handleSetSocialAssetConfig);
}

function* handleOPenInviteWatcher() {
  yield takeEvery(openInvite.TRIGGER, handleOpenInvite);
}

function* handleFetchAllOrganisationsWatcher() {
  yield takeEvery(fetchAllOrganisations.TRIGGER, handleFetchAllOrganisations);
}

function* handleUpdateMemberUserProfileImageWatcher() {
  yield takeEvery(
    updateMemberUserProfileImage.TRIGGER,
    handleUpdateMemberUserProfileImage
  );
}

function* handleFetchAllHeadOfficeOrganisationsWatcher() {
  yield takeEvery(
    fetchAllHeadOfficeOrganisations.TRIGGER,
    handleFetchAllHeadOfficeOrganisations
  );
}

function* handleSearchOrganisationWatcher() {
  yield takeEvery(fetchOrganisationByName.TRIGGER, handleSearchOrganisation);
}

function* handleSelectedOrganisationWatcher() {
  yield takeEvery(selectOrganisation.TRIGGER, handleSelectedOrganisation);
}

function* handleFetchOrganisationByIdWatcher() {
  yield takeEvery(fetchOrganisationById.TRIGGER, handleFetchOrganisationById);
}

function* handleFetchMemberRolesWatcher() {
  yield takeEvery(fetchMemberRoles.TRIGGER, handleFetchMemberRoles);
}

function* handleUpdateMemberWatcher() {
  yield takeEvery(updateMember.TRIGGER, handleUpdateMember);
}

function* handleUpdateUserMemberWatcher() {
  yield takeEvery(updateUserMember.TRIGGER, handleUpdateUserMember);
}

function* handleRemoveOrganisationServiceAreaWatcher() {
  yield takeEvery(
    removeOrganisationServiceArea.TRIGGER,
    handleRemoveOrganisationServiceArea
  );
}
function* handleAddMemberToOrganisationServiceAreaWatcher() {
  yield takeEvery(
    addMemberToOrganisationServiceArea.TRIGGER,
    handleAddMemberToOrganisationServiceArea
  );
}
function* handleRemoveMemberFromOrganisationServiceAreaWatcher() {
  yield takeEvery(
    removeMemberFromOrganisationServiceArea.TRIGGER,
    handleRemoveMemberFromOrganisationServiceArea
  );
}
function* handleAddOrganisationServiceAreaWatcher() {
  yield takeEvery(
    addOrganisationServiceArea.TRIGGER,
    handleAddOrganisationServiceArea
  );
}

function* handleRemoveNotificationRecipientsWatcher() {
  yield takeEvery(
    removeNotificationRecipients.TRIGGER,
    handleRemoveNotificationRecipients
  );
}

function* handleUpdateNotificationRecipientsWatcher() {
  yield takeEvery(
    updateNotificationRecipients.TRIGGER,
    handleUpdateNotificationRecipients
  );
}

function* handleUpdateDefaultNotificationRecipientWatcher() {
  yield takeEvery(
    updateDefaultNotificationRecipient.TRIGGER,
    handleUpdateDefaultNotificationRecipient
  );
}

function* handleUpdateMemberProfileImageWatcher() {
  yield takeEvery(
    updateMemberProfileImage.TRIGGER,
    handleUpdateMemberProfileImage
  );
}

function* handleToggleFullAddressWatcher() {
  yield takeEvery(toggleFullAddress.TRIGGER, handleToggleFullAddress);
}

function* updateShowPartnersWatcher() {
  yield takeEvery(updateShowPartners.TRIGGER, handleShowPartners);
}

function* updateShowReferralPartnersWatcher() {
  yield takeEvery(
    updateShowReferralPartners.TRIGGER,
    handleShowReferralPartners
  );
}

function* handleResendMemberInviteWatcher() {
  yield takeEvery(resendMemberInvitation.TRIGGER, handleResendMemberInvite);
}

function* handleFetchInvitedMembersWatcher() {
  yield takeEvery(fetchInvitedMembers.TRIGGER, handleFetchInvitedMembers);
}

function* handlePatchOrganisationWatcher() {
  yield takeEvery(patchOrganisation.TRIGGER, handlePatchOrganisation);
}

function* handleFetchPendingOrganisationSubscriptionWatcher(): any {
  yield takeEvery(
    fetchPendingOrganisationSubscription.TRIGGER,
    handleFetchPendingOrganisationSubscription
  );
}

function* handlePatchOrganisationStateWatcher() {
  yield takeEvery(patchOrganisationState.TRIGGER, handlePatchOrganisationState);
}

function* handlePatchFbSocialAssetWatcher() {
  yield takeEvery(patchFbSocialAsset.TRIGGER, handlePatchFbSocialAsset);
}

function* handlePatchSubOrgFbSocialAssetWatcher() {
  yield takeEvery(
    patchSubOrgFbSocialAsset.TRIGGER,
    handlePatchSubOrgFbSocialAsset
  );
}

function* postAuthOrgInitWatcher() {
  yield takeEvery(postAuthOrgInit.TRIGGER, handlePostAuthOrgInit);
}

function* handleSmartFeedSettingsWatcher() {
  yield takeEvery(patchSmartFeedSettings.TRIGGER, handlePatchSmartFeedSettings);
}

function* handleSmartFeedEventsWatcher() {
  yield takeEvery(getSmartFeedEvents.TRIGGER, handleSmartFeedEvents);
}

function* handleRetrySmartFeedEventWatcher() {
  yield takeEvery(retrySmartFeedEvent.TRIGGER, handleRetrySmartFeedEvent);
}

function* handleMoveFbPageToOrgWatcher() {
  yield takeEvery(moveFbPageToOrg.TRIGGER, handleMoveFbPageToOrg);
}

function* handleRemoveUserFbPageWatcher() {
  yield takeEvery(removeUserFbPage.TRIGGER, handleRemoveUserFbPage);
}

function* handleSmartFeedTriggerSyndicatorWatcher() {
  yield takeEvery(
    triggerSmartFeedSyndicator.TRIGGER,
    handleSmartFeedTriggerSyndicator
  );
}

function* handleCampaignSettingsWatcher() {
  yield takeEvery(setCampaignSettings.TRIGGER, handleCampaignSettings);
}

function* handleUpdateMemberUserDetailsWatcher() {
  yield takeEvery(
    updateMemberUserDetails.TRIGGER,
    handleUpdateMemberUserDetails
  );
}

export function* organisationSaga() {
  yield all([
    fork(organisationErrorWatcher),
    fork(handleFetchMembersWithSubOrgMembersWatcher),
    fork(fetchOrganisationByMemberWatcher),
    fork(updateOrganisationWatcher),
    fork(handleAddMemberWatcher),
    fork(handleDeleteMemberWatcher),
    fork(handleInviteMemberWatcher),
    fork(handleUploadLogoWatcher),
    fork(handleUploadCoverImageWatcher),
    fork(handleSetNotificationsWatcher),
    fork(handleSetColorsWatcher),
    fork(handleSetTestModeWatcher),
    fork(handleSetPaymentMethodsWatcher),
    fork(handleSetEngagementMaximiserWatcher),
    fork(handleSetOrganisationSettingsWatcher),
    fork(handleSetLocaleSettingsWatcher),
    fork(handleSetSyndicatorSettingsWatcher),
    fork(handleOPenInviteWatcher),
    fork(handleFetchAllOrganisationsWatcher),
    fork(handleFetchAllHeadOfficeOrganisationsWatcher),
    fork(handleSearchOrganisationWatcher),
    fork(handleSelectedOrganisationWatcher),
    fork(handleFetchOrganisationByIdWatcher),
    fork(handleFetchMemberRolesWatcher),
    fork(handleUpdateMemberWatcher),
    fork(handleUpdateUserMemberWatcher),
    fork(handleUpdateMemberUserProfileImageWatcher),
    fork(handleAddOrganisationServiceAreaWatcher),
    fork(handleRemoveMemberFromOrganisationServiceAreaWatcher),
    fork(handleAddMemberToOrganisationServiceAreaWatcher),
    fork(handleRemoveOrganisationServiceAreaWatcher),
    fork(handleRemoveNotificationRecipientsWatcher),
    fork(handleUpdateNotificationRecipientsWatcher),
    fork(handleSetSocialAssetConfigWatcher),
    fork(handleUpdateDefaultNotificationRecipientWatcher),
    fork(handleUpdateMemberProfileImageWatcher),
    fork(handleFetchSubOrganisationByIdWatcher),
    fork(handleResetSubOrganisationWatcher),
    fork(handleToggleFullAddressWatcher),
    fork(handleResendMemberInviteWatcher),
    fork(handleFetchInvitedMembersWatcher),
    fork(handlePatchOrganisationWatcher),
    fork(handleFetchPendingOrganisationSubscriptionWatcher),
    fork(handlePatchOrganisationStateWatcher),
    fork(handlePatchFbSocialAssetWatcher),
    fork(handlePatchSubOrgFbSocialAssetWatcher),
    fork(postAuthOrgInitWatcher),
    fork(updateShowPartnersWatcher),
    fork(handleSmartFeedSettingsWatcher),
    fork(handleSmartFeedEventsWatcher),
    fork(handleRetrySmartFeedEventWatcher),
    fork(updateShowReferralPartnersWatcher),
    fork(handleSmartFeedTriggerSyndicatorWatcher),
    fork(handleMoveFbPageToOrgWatcher),
    fork(handleRemoveUserFbPageWatcher),
    fork(handleCampaignSettingsWatcher),
    fork(handleUpdateMemberUserDetailsWatcher),
  ]);
}
